import type { AppProps } from 'next/app'
import Script from 'next/script';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

/** Integrations */
import * as fbq from '@libs/integrations/fbpixel';
import { GTM_ID, pageview as gtmPageView } from '@libs/integrations/gtm';
import { updateVisitedPage } from '@libs/utils/visitor.utils';
import TagManager from 'react-gtm-module';
import MainLayout from '@layouts/main-layout/main-layout';
import ErrorPageComponent from '@components/shared/error-page/error-page';

function MyAppProduction({Component, pageProps}: AppProps) {
    const router = useRouter();

    useEffect(() => {
        // This pageview only triggers the first time (it's important for Pixel to have real information)
        fbq.pageview();
        updateVisitedPage(window.location.pathname).catch(err => void 0);

        const handleRouteChange = (url: string) => {
            fbq.pageview();
            gtmPageView(url);
            updateVisitedPage(url).catch(err => void 0);
        }

        TagManager.initialize({ gtmId: GTM_ID });

        router.events.on('routeChangeComplete', handleRouteChange)
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange)
        }
    }, [router.events]);

    return <>
        <Script
            id={'facebook-pixel'}
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
                __html: `
                !function(f,b,e,v,n,t,s)
                {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                n.queue=[];t=b.createElement(e);t.async=!0;
                t.src=v;s=b.getElementsByTagName(e)[0];
                s.parentNode.insertBefore(t,s)}(window, document,'script',
                'https://connect.facebook.net/en_US/fbevents.js');
                fbq('init', ${fbq.FB_PIXEL_ID});
          `
            }}
        />
        <Script
            id={'cookie-policy'}
            strategy='afterInteractive'
            dangerouslySetInnerHTML={{
                __html: `
          var _iub = _iub || [];
          _iub.csConfiguration = {"lang": "en", "siteId": 1439442, "cookiePolicyId": 83355487};
        `
            }}
        />
        <Script src={'//cdn.iubenda.com/cs/iubenda_cs.js'} async={true} strategy={'afterInteractive'}/>

        {!pageProps.error ? <Component {...pageProps} /> : (
          <MainLayout>
              <ErrorPageComponent
                errorStatus={pageProps.error.errorStatus}
                prefix={pageProps.error.message}
              />
          </MainLayout>
        )}
    </>
}

export default MyAppProduction;
