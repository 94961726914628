import styles from './large-modal-window.module.scss';
import { createRef, ReactNode, useEffect } from 'react';

interface ModalWindowProps {
    children: ReactNode;
    onClose: () => void
}

const LargeModalWindow = ({ children, onClose }: ModalWindowProps) => {
    let modal:any = createRef();

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = 'auto';
        }
    }, []);

    useEffect(() => {
        function closeModal(event: any) {
            if (modal.current === event.target) {
                onClose();
            }
        }
        document.addEventListener('click', closeModal)
        return () => document.removeEventListener('click', closeModal)
    }, [])

    return (
        <div ref={modal} className={styles.layout}>
            <div className={styles.container}>
                {children}
            </div>
        </div>
    )
}

export default LargeModalWindow;
