import {
  ExpertisePreviewState
} from '@components/shared/header/nav-previews/expertise-preview/expertise-preview.state';
import styles from './expertise-mobile-preview.module.scss';
import Link from 'next/link';
import { Themes } from '@layouts/main-layout/themes.enum';
import { ReactSVG } from 'react-svg';

type ExpertiseMobilePreviewProps = {
  theme: Themes;
  onMenuClicked: () => void
}

export const ExpertiseMobilePreview = ({ theme, onMenuClicked }: ExpertiseMobilePreviewProps) => {
  const { cards } = ExpertisePreviewState;
  const themedClasses = (classes: string) => `${classes} ${styles[theme]}`;
  return (
    <div className={styles.cards}>
      {cards.map((card, key) => (
        <div key={key} className={styles.cards__default}>
          <h4 className={`${styles.cards__title__md} ${styles.cards__default_title}`}>{card.title}</h4>
          <div className={styles.cards__body}>
            {card.links.map((link, key) => (
              <Link key={key} href={link.href}>
                <a onClick={onMenuClicked} className={themedClasses(styles.cards__text)}>
                  <ReactSVG src={link.icon} />
                  <span>{link.label}</span>
                </a>
              </Link>
            ))}
          </div>
        </div>
      ))}
      <Link href="/expertise">
        <a onClick={onMenuClicked} className={themedClasses(styles.cards__button)}>View our full expertise</a>
      </Link>
    </div>
  )
}

export default ExpertiseMobilePreview;
