import { useEffect } from 'react';
import { scriptLoader } from '../utils/script.utils';
import { useInView } from 'react-intersection-observer';

export const SITE_KEY = '6Ld6icMUAAAAADb8zj4oZOBakAgnrEUV7RI8etxa';

declare const grecaptcha: any;
let isLoadingCaptcha = false;

/** Allows to generate grecaptcha token for server side validation
 * @param action
 */
export function recaptchaToken(action = 'contact_form'): Promise<string> {
    if (typeof grecaptcha === 'undefined') {
        return Promise.reject(new Error('Recaptcha not loaded'));
    }
    return new Promise((resolve, reject) => {
        grecaptcha.ready(() => {
            grecaptcha.execute(SITE_KEY, {action})
                .then((token: string) => resolve(token))
                .catch((err: any) => reject(err));
        });
    });
}

/** Component which loads the grecaptcha script when becomes visible */
const GrecaptchaLoader = () => {

    const [inViewRef, inView] = useInView({
        threshold: 0,
        triggerOnce: true
    });

    useEffect(() => {
        if (inView) {
            loadCaptcha();
        }
    }, [inView]);

    const loadCaptcha = () => {
        if (typeof grecaptcha !== 'undefined' || isLoadingCaptcha) {
            return;
        }
        const url = 'https://www.google.com/recaptcha/api.js?render=' + SITE_KEY;
        isLoadingCaptcha = true;
        scriptLoader(url,
            () => isLoadingCaptcha = false,
            () => isLoadingCaptcha = false,
        );
    }

    return (
        <div ref={inViewRef}/>
    )
}

export default GrecaptchaLoader;
