import { recaptchaToken } from "@libs/integrations/grecaptcha";
import { apiHost } from "@libs/providers/api-host.provider";
import { getVisitorSecret } from "@libs/utils/visitor.utils";
import { HttpStatuses } from '@services/project.service';

export interface IAttachments {
    lastModified: number;
    lastModifiedDate: Date;
    name: string;
    size: number;
    type: string;
    webkitRelativePath: string;
}

export async function sendFeedback(
    name: string,
    email: string,
    message: string,
    phone: string = '',
    createdByClient: boolean,
    company: string = '',
    budget: string = '',
    attachments: IAttachments[]
) {
    const token = await recaptchaToken();
    const formData = new FormData();
    formData.set("name", name);
    formData.set("email", email);
    formData.set("message", message);
    formData.set("phone", phone);
    formData.set("company", company);
    formData.set("budget", budget);
    formData.set('g-recaptcha-response', token);
    formData.set("visitorSecret", getVisitorSecret());
    formData.set("createdByClient", JSON.stringify(createdByClient));
    attachments.forEach((attachment: any) => formData.append("attachments[]", attachment));
    const url = `${apiHost()}/web/feedbacks`;
    return fetch(url, {
        method: "POST",
        body: formData,
    }).then((res) => res.json());
}

export async function getCountyCode() {
    const url = `${apiHost()}/web/feedbacks/country`;
    return fetch(url, {
        method: "GET",
    }).then((res) => res.json());
}
