export function scriptLoader(scriptURL: string, onLoad?: () => void, onError?: (error: Error) => void): void {
    const script = document.createElement("script");
    script.src = scriptURL;
    script.async = true;

    script.onload = () => {
        onLoad?.()
    };
    script.onerror = (event) => {
        const err = event.toString();
        onError?.(new Error(err));
    };

    document.body.appendChild(script);
}
