import { BlogPreviewState } from '@components/shared/header/nav-previews/blog-preview/blog-preview.state';
import styles from './blog-mobile-preview.module.scss';
import Link from 'next/link';
import { Themes } from '@layouts/main-layout/themes.enum';
import { ReactSVG } from 'react-svg';

type BlogMobilePreviewProps = {
  theme: Themes;
  onMenuClicked: () => void;
}

export const BlogMobilePreview = ({theme, onMenuClicked}: BlogMobilePreviewProps) => {
  const {cards} = BlogPreviewState;

  const themedClasses = (classes: string) => `${classes} ${styles[theme]}`;

  return (
    <div className={styles.cards}>
      {cards.map((card, key) => (
        <Link key={key} href={card.link}>
          <a onClick={onMenuClicked} className={styles.cards__default}>
            <ReactSVG className={themedClasses(styles.cards__default_icon)} src={card.icon}/>
            <h4 className={themedClasses(`${styles.cards__title__md} ${styles.cards__default_title}`)}>{card.title}</h4>
          </a>
        </Link>
      ))}
      <Link href="/blog">
        <a onClick={onMenuClicked} className={themedClasses(styles.cards__button)}>View our blog</a>
      </Link>
    </div>
  )
}

export default BlogMobilePreview;
