import { isProduction } from "@libs/environment";
import ServicesPreview from '@components/shared/header/nav-previews/services-preview/services-preview';
import ExpertisePreview from '@components/shared/header/nav-previews/expertise-preview/expertise-preview';
import BlogPreview from '@components/shared/header/nav-previews/blog-preview/blog-preview';
import ServicesMobilePreview
  from '@components/shared/header/nav-previews/services-mobile-preview/services-mobile-preview';
import ExpertiseMobilePreview
  from '@components/shared/header/nav-previews/expertise-mobile-preview/expertise-mobile-preview';
import BlogMobilePreview from '@components/shared/header/nav-previews/blog-mobile-preview/blog-mobile-preview';
import { Themes } from '@layouts/main-layout/themes.enum';
import { Dispatch, SetStateAction } from 'react';

export const ARROW_ICON = '/assets/icons/icn-arrow.svg';

type HeaderState = {
  logo: string;
  darkModeLogo: string;
  logoMobile: string;
  darkModeLogoMobile: string;
  navigation: HeaderNavigation[];
};

export type HeaderNavigation = {
  name: string;
  link: string;
  preview?: (theme: Themes, setViewingLink?: Dispatch<SetStateAction<string>>) => JSX.Element;
  burgerPreview?: (theme: Themes, onMenuClicked: () => void) => JSX.Element;
}

const careersLink: string = isProduction()
  ? "https://careers.agilie.com"
  : "https://careers-stage.agilie.com";

export const HeaderState: HeaderState = {
  logo: `/assets/header/logo/agilie-logo.svg`,
  darkModeLogo: "/assets/header/logo/agilie-logo-white.svg",
  logoMobile: `/assets/header/logo/agilie-logo-mobile.svg`,
  darkModeLogoMobile: "/assets/header/logo/agilie-logo-white-mobile.svg",
  navigation: [
    {
      name: `Services`,
      link: `/services`,
      preview: (theme: Themes) => <ServicesPreview theme={theme}/>,
      burgerPreview: (theme: Themes, onMenuClicked: () => void) => <ServicesMobilePreview theme={theme} onMenuClicked={onMenuClicked}/>
    },
    {
      name: `Expertise`,
      link: `/expertise`,
      preview: (theme: Themes) => <ExpertisePreview theme={theme}/>,
      burgerPreview: (theme: Themes, onMenuClicked: () => void) => <ExpertiseMobilePreview theme={theme} onMenuClicked={onMenuClicked}/>
    },
    {
      name: `Success Stories`,
      link: `/success-stories`,
    },
    {
      name: `Company`,
      link: `/company`,
    },
    {
      name: `Careers`,
      link: careersLink,
    },
    {
      name: `Blog`,
      link: `/blog`,
      preview: (theme: Themes, setViewingLink?: Dispatch<SetStateAction<string>>) => <BlogPreview theme={theme} setViewingLink={setViewingLink}/>,
      burgerPreview: (theme: Themes, onMenuClicked: () => void) => <BlogMobilePreview theme={theme} onMenuClicked={onMenuClicked}/>
    },
    {
      name: `Contact`,
      link: `/contacts`,
    },
  ],
};
