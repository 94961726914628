import PreviewWrapper from '@components/shared/header/nav-previews/preview-wrapper/preview-wrapper';
import styles from './blog-preview.module.scss';
import Link from 'next/link';
import { ReactSVG } from 'react-svg';
import { BlogPreviewState } from '@components/shared/header/nav-previews/blog-preview/blog-preview.state';
import { Themes } from '@layouts/main-layout/themes.enum';
import { Dispatch, SetStateAction } from 'react';

type BlogPreviewProps = {
  theme: Themes;
  setViewingLink?: Dispatch<SetStateAction<string>>
}

const BlogPreview = ({theme, setViewingLink}: BlogPreviewProps) => {
  const {title, subtitle, icon, cards} = BlogPreviewState;

  const themedClasses = (classes: string) => `${classes} ${styles[theme]}`;

  return (
    <PreviewWrapper theme={theme}>
      <div className={themedClasses(styles.cards)}>
        <div className={themedClasses(styles.cards__main)}>
          <h3 className={styles.cards__title__lg}>{title}</h3>
          <p className={styles.cards__subtitle}>{subtitle}</p>
          <Link href={'/blog'}>
            <a onClick={() => setViewingLink!('')} className={themedClasses(styles.cards__link)}>
              <span>Learn more</span>
              <ReactSVG className={themedClasses(styles.icon)} src={icon}/>
            </a>
          </Link>
        </div>
        <div className={styles.cards__list}>
          {cards.map((card, key) => (
              <Link key={key} href={card.link}>
                <a onClick={() => setViewingLink!('')} className={styles.cards__default}>
                  <ReactSVG className={themedClasses(styles.cards__default_icon)} src={card.icon}/>
                  <p className={styles.cards__block}>
                    <h4 className={themedClasses(`${styles.cards__title__md} ${styles.cards__default_title}`)}>{card.title}</h4>
                    <span className={themedClasses(`${styles.cards__text} ${styles.cards__default_text}`)}>{card.text}</span>
                  </p>
                </a>
              </Link>
          ))}
        </div>
      </div>
    </PreviewWrapper>
  )
}

export default BlogPreview;
