import LargeModalWindow from '../../modals/large-modal/large-modal-window';

import style from './start-project.module.scss';
import closeIcon from "/public/assets/icons/icn-close.svg";
import { useRouter } from 'next/router';
import Link from 'next/link';
import Image from 'next/image';
import FeedbackForm from '../../forms/feedback-form/feedback-form';
import { ComponentType, useEffect, useState } from 'react';
import FormSubmitStatus from '../../forms/form-submit-status/form-submit-status';

// TODO: Duplication. Need to be fixed
enum FORM_STATUS {
    active = 'active',
    success = 'success',
    error = 'error',
}

const COVER_REMOVE_TIMEOUT = 3000; // milliseconds

interface StartProjectModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const StartProjectModal = ({isOpen, onClose}: StartProjectModalProps) => {

    const router = useRouter();
    const [isFormSubmitted, setFormSubmitted] = useState(false);
    const [currentFormStatus, setFormStatus] = useState(FORM_STATUS.active);

    useEffect(() => {
        if (currentFormStatus === FORM_STATUS.success) {
            setTimeout(() => {
                onClose();
                setFormStatus(FORM_STATUS.active);
            }, COVER_REMOVE_TIMEOUT);
        }
        if (currentFormStatus === FORM_STATUS.error) {
            setTimeout(() => {
                setFormStatus(FORM_STATUS.active);
            }, COVER_REMOVE_TIMEOUT);
        }
    }, [currentFormStatus]);

    const onSubmit = (success: boolean) => {
        if (success) {
            setFormStatus(FORM_STATUS.success);
        } else {
            setFormStatus(FORM_STATUS.error);
        }
    }

    if (!isOpen) {
        return null;
    }

    return (
        <LargeModalWindow onClose={onClose}>
            <div className={style.header}>
                <div className={style.title}>
                    Start a project
                </div>
                <Link scroll={false} href={router.asPath}>
                    <a onClick={() => onClose?.()} aria-label="close modal">
                        <Image src={closeIcon} width="16px" height="20px" alt="close"/>
                    </a>
                </Link>
            </div>
            <div className={style.content}>
                <FeedbackForm currentFormStatus={currentFormStatus} setFormSubmitted={(status: boolean) => setFormSubmitted(status)} isFormSubmitted={isFormSubmitted} onSubmit={(success => onSubmit(success))}/>
                {
                    currentFormStatus !== FORM_STATUS.active &&
                    <div className={style.status__wrapper}>
                        <FormSubmitStatus status={currentFormStatus === FORM_STATUS.success ? 'success' : 'failure'}/>
                    </div>
                }
            </div>
        </LargeModalWindow>

    );
}

export default StartProjectModal;
