import styles from './services-preview.module.scss';
import PreviewWrapper from '@components/shared/header/nav-previews/preview-wrapper/preview-wrapper';
import { ServicesPreviewState } from '@components/shared/header/nav-previews/services-preview/services-preview.state';
import { ReactSVG } from 'react-svg';
import Link from 'next/link';
import { Themes } from '@layouts/main-layout/themes.enum';

type ServicesPreviewProps = {
  theme: Themes;
}

const ServicesPreview = ({theme}: ServicesPreviewProps) => {
  const { title, subtitle, icon, cards } = ServicesPreviewState;

  const themedClasses = (classes: string) => `${classes} ${styles[theme]}`;

  return (
    <PreviewWrapper theme={theme}>
      <div className={themedClasses(styles.cards)}>
        <div className={themedClasses(styles.cards__main)}>
          <h3 className={styles.cards__title__lg}>{title}</h3>
          <p className={styles.cards__subtitle}>{subtitle}</p>
          <Link href={'/services'}>
            <a className={themedClasses(styles.cards__link)}>
              <span>Learn more</span>
              <ReactSVG className={themedClasses(styles.icon)} src={icon}/>
            </a>
          </Link>
        </div>
        <div className={styles.cards__list}>
          {cards.map((card, key) => (
            <Link key={key} href={card.link}>
              <a className={themedClasses(styles.cards__default)}>
                <ReactSVG className={themedClasses(styles.cards__default_icon)} src={card.icon}/>
                <span className={styles.cards__block}>
                  <h4 className={themedClasses(`${styles.cards__title__md} ${styles.cards__default_title}`)}>{card.title}</h4>
                  <span className={themedClasses(`${styles.cards__text} ${styles.cards__default_text}`)}>{card.text}</span>
                </span>
              </a>
            </Link>
          ))}
        </div>
      </div>
    </PreviewWrapper>
  )
}

export default ServicesPreview;
