import { ARROW_ICON, HeaderNavigation } from "@components/shared/header/header.state";
import style from "./nav-item.module.scss";
import Link from "next/link";
import { ReactSVG } from "react-svg";
import { useRouter } from "next/router";
import { Themes } from "@layouts/main-layout/themes.enum";
import { Dispatch, SetStateAction } from 'react';

type NavItemProps = {
  viewingLink: string;
  setViewingLink: Dispatch<SetStateAction<string>>;
  item: HeaderNavigation;
  theme?: Themes;
};

export const NavItem = ({ item: { name, link, preview }, viewingLink, setViewingLink, theme = Themes.Default }: NavItemProps) => {
  const { pathname } = useRouter();

  const conditionalStyles = pathname.includes(link.toLocaleLowerCase())
    ? style.item__link_active
    : '';
  const iconConditionalStyles = viewingLink === link ? style.item__icon_active : '';
  return (
    <>
      <div
        onMouseEnter={() => setViewingLink(link)}
        onMouseLeave={() => setViewingLink('')}
        className={`${style.item} ${style[theme]}`}
      >
        <Link href={link}>
          <a onClick={() => setViewingLink('')} className={`${style.item__link} ${conditionalStyles}`}>{name}</a>
        </Link>
        {preview ? <ReactSVG className={`${style.item__icon} ${style[theme]} ${iconConditionalStyles}`} src={ARROW_ICON} width={'14px'}
                             height={'14px'}/> : ''}
        {viewingLink === link ? preview?.(theme, setViewingLink) : ''}
      </div>
    </>
  );
};

