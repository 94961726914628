import { isProduction } from '@libs/environment';
import { config } from '../../../config';

const careersLink = isProduction()
  ? 'https://careers.agilie.com'
  : 'https://careers-stage.agilie.com';
const year = new Date().getFullYear();

type FooterState = {
  brand: string;
  logo: string;
  rights: string;
  year: number;
  social: FooterSocial;
  columns: FooterColumn[];
  contacts: IFooterContacts;
}

type FooterColumn = Array<{
  label: string;
  link: string;
  hTag?: boolean;
  topGap?: boolean;
}>;

type FooterSocial = Array<{
  name: string;
  link: string;
  icon: string;
}>

interface IFooterContacts {
  title: string;
  address: {
    ukraine: string;
    slovakia: string;
  };
  titleOficce: string;
  number: {
    usa: string;
    slovakia: string;
  };
  email: string;
}

export const FooterState: FooterState = {
  brand: 'Agilie',
  logo: '/assets/header/logo/agilie-logo-white.svg',
  rights: 'All Rights Reserved',
  year: year,
  columns: [
    [
      {
        label: 'Expertise',
        link: '/expertise',
        hTag: true,
      },
      {
        label: 'Fintech',
        link: '/expertise/fintech',
      },
      {
        label: 'Mobile banking',
        link: '/expertise/fintech/mobile-banking',
      },
      {
        label: 'Payment solutions',
        link: '/expertise/fintech/payment-solutions',
      },
      {
        label: 'Blockchain software',
        link: '/expertise/fintech/blockchain-software',
      },
      {
        label: 'All technologies',
        link: '/expertise/technology',
      },
      {
        label: 'UX/UI design',
        link: '/expertise/technology/ui-ux-design',
      },
      {
        label: 'Mobile development',
        link: '/expertise/technology/mobile-development',
      },
      {
        label: 'Web development',
        link: '/expertise/technology/web-development',
      },
      {
        label: 'QA testing',
        link: '/expertise/technology/qa-testing',
      },
      {
        label: 'MVP development',
        link: '/expertise/technology/mvp-development',
      },
      {
        label: 'Agilie insights',
        link: '/expertise/insights',
      },
      {
        label: 'Discovery phase',
        link: '/expertise/discovery',
      },
      {
        label: 'SDLC',
        link: '/expertise/insights/sdlc',
      },
    ],
    [
      {
        label: 'Blog',
        link: '/blog',
        hTag: true,
      },
      {
        label: 'Mobile',
        link: '/blog?topic=mobile',
      },
      {
        label: 'Web',
        link: '/blog?topic=web',
      },
      {
        label: 'Design',
        link: '/blog?topic=design',
      },
      {
        label: 'Insights',
        link: '/blog?topic=insights',
      },
      {
        label: 'Blockchain',
        link: '/blog?topic=blockchain',
      },
      {
        label: 'Public',
        link: '/blog?topic=public',
      },
      {
        label: 'Services',
        link: '/services',
        hTag: true,
        topGap: true,
      },
      {
        label: 'Dedicated teams',
        link: '/services/dedicated-teams',
      },
      {
        label: 'Staff augmentation',
        link: '/services/staff-augmentation',
      },
      {
        label: 'IT outsourcing',
        link: '/services/it-outsourcing',
      },
    ],
    [
      {
        label: 'Success stories',
        link: '/success-stories',
        hTag: true,
      },
      {
        label: 'Company',
        link: '/company',
        hTag: true,
      },
      {
        label: 'Careers',
        link: careersLink,
        hTag: true,
      },
    ]
  ],
  social: [
    {
      name: 'Behance',
      link: 'https://www.behance.net/agilie',
      icon: '/layout-images/footer/behance.png'
    },
    {
      name: 'Dribbble',
      link: 'https://dribbble.com/agilie',
      icon: '/layout-images/footer/dribbble.png',
    },
    {
      name: 'Github',
      link: 'https://github.com/agilie',
      icon: '/layout-images/footer/github.png'
    },
    {
      name: 'Instagram',
      link: 'https://www.instagram.com/agilie_team/',
      icon: '/layout-images/footer/instagram.png',
    },
    {
      name: 'Facebook',
      link: 'https://www.facebook.com/AgilieTeam/',
      icon: '/layout-images/footer/facebook.png'
    },
    {
      name: 'Linkedin',
      link: 'https://www.linkedin.com/company/agilie',
      icon: '/layout-images/footer/linkedin.png',
    },
  ],
  contacts: {
    title: 'Contact us',
    number: {
      usa: '+1 617 778 2988',
      slovakia: '+421 233 070 885',
    },
    email: 'sales@agilie.com',
    titleOficce: 'Offices',
    address: {
      slovakia: `Michalska str, 9, Bratislava, 811 03, Slovakia`,
      ukraine: `V.Monomakh str, 17a, Dnipro, 49000, Ukraine`,
    },
  },
};

export interface IFooterStructureData {
  '@context': 'https://schema.org';
  '@type': 'Organization';
  url: string;
  name: string;
  logo: string;
  email: string;
  sameAs: string[];
  address: IFooterStructureDataAddress;
  contactPoint: IFooterStructureDataContactPoint[];
}

interface IFooterStructureDataAddress {
  '@type': 'PostalAddress';
  streetAddress: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  addressCountry: string;
}

interface IFooterStructureDataContactPoint {
  '@type': 'ContactPoint',
  telephone: string;
  contactType: string;
  areaServed: string;
  availableLanguage: string | string[];
}

export const footerStructureData: IFooterStructureData = {
  "@context": "https://schema.org",
  "@type": "Organization",
  url: config.HOST,
  name: "Agilie",
  logo: "https://agilie.com/_next/image?url=/assets/header/logo/agilie-logo.svg&w=96&q=75",
  email: "info@agilie.com",
  sameAs: [
    "https://twitter.com/agile_ninjas",
    "https://www.facebook.com/AgilieTeam/",
    "https://dribbble.com/agilie",
    "https://www.behance.net/agilie",
    "https://github.com/agilie",
    "https://www.instagram.com/agilie_team/",
    "https://www.linkedin.com/company/agilie",
    "https://clutch.co/profile/agilie",
  ],
  address: {
    "@type": "PostalAddress",
    streetAddress: "Volodymyra Monomakha, 17А",
    addressLocality: "Dnipro",
    addressRegion: "Dnipropetrovsk Oblast",
    postalCode: "49000",
    addressCountry: "Ukraine",
  },
  contactPoint: [
    {
      "@type": "ContactPoint",
      telephone: "+1 617 778-2988",
      contactType: "customer support",
      areaServed: "US",
      availableLanguage: "English",
    },
    {
      "@type": "ContactPoint",
      telephone: "+38 056 377-9779",
      contactType: "customer service",
      areaServed: "UA",
      availableLanguage: ["English", "Ukrainian"],
    },
  ],
};
