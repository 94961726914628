export function isStaging(): boolean {
    return process.env.NEXT_PUBLIC_ENVIRONMENT === 'staging';
}

export function isProduction(): boolean {
    return process.env.NEXT_PUBLIC_ENVIRONMENT === 'production';
}

export function isDockerEnv(): boolean {
    return isStaging() || isProduction();
}
