/** This module contains utils for special visitor logic implementation
 * which allows to track user's information and visited pages.
 * Should be revised in future but for now it's implemented according to the current backend logic.
 */
import { config } from '../../config';
import { apiHost } from '@libs/providers/api-host.provider';

enum CookieType {
    secret = 'secret',
    session = 'session',
}

let visitorSecret: string;
let visitorSession: string;
if (typeof window !== 'undefined') {
    visitorSecret = findOrCreateVisitorSecret();
    visitorSession = findOrCreateVisitorSession();
}

export function getVisitorSecret(): string {
    return visitorSecret;
}

export async function updateVisitedPage(url: string) {
    await updateVisitor({
        page: `${config.HOST}${url}`
    });
}

function updateVisitor(data: any): Promise<Response> {
    return fetch(`${apiHost()}/visitors/${visitorSecret}:${visitorSession}`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
}

function findOrCreateVisitorSecret(): string {
    return _findOrSetCookie(CookieType.secret, 365);
}

function findOrCreateVisitorSession(): string {
    return _findOrSetCookie(CookieType.session);
}

function _findOrSetCookie(cookieType: CookieType, expiration?: number): string {
    const cookieMatch = document.cookie.match(new RegExp(`agilie_${cookieType}=(.*?)(?=;|$)`));
    let secret;
    if (cookieMatch) {
        secret = cookieMatch[1];
    } else {
        secret = _generateSecret();
        let cookie = `agilie_${cookieType}=${secret}; path=/;`;
        if (expiration) {
            const date = new Date;
            date.setDate(date.getDate() + expiration);
            cookie += `expires=${date.toUTCString()};`;
        }
        document.cookie = cookie;
    }
    return secret;
}

function _generateSecret(length = 18): string {
    const ALPHABET = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

    let text = '';
    for (let i = 0; i < length; i++) {
        text += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length));
    }

    return text;
}
