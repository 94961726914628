/** Core Libs */
import dynamic from 'next/dynamic';
import { ReactNode, useEffect, useState } from 'react';

/** Styles */
import styles from './main-layout.module.scss';

/** Components */
import Footer from '@components/shared/footer/footer';
import Header from '@components/shared/header/header';
import { Themes } from '@layouts/main-layout/themes.enum';
//TODO: need to find correct type
const BurgerMenu = dynamic(() => import('@components/shared/header/burger-menu/burger-menu'));
/** It is a header height which can be found in `styles/_variables.scss`: $headerHeight */
const HEADER_HEIGHT = 80; // Without banner "Save Ukraine"

interface LayoutProps {
    children: ReactNode;
    theme?: Themes;
}

const tabletWidth = 1140;
const MainLayout = ({children, theme}: LayoutProps) => {

    const [pageScrolled, setPageScrolled] = useState(false);
    const [menuOpened, setMenuOpened] = useState(false);
    const [offsetWidth, setOffsetWidth] = useState(0);

    useEffect(() => {
        onResize()
        window.onresize = onResize;
        function onResize() {
            setOffsetWidth(document.body.offsetWidth)
            if(offsetWidth > tabletWidth && menuOpened) {
                setMenuOpened(false);
                document.body.style.overflow = 'auto';
            }
        }
        return function() {
            window.onresize = null;
        }
    }, [offsetWidth])

    function onMenuClicked() {
        setMenuOpened(!menuOpened);
        if (menuOpened) {
            document.body.style.overflow = 'auto';
        } else {
            document.body.style.overflow = 'hidden';
        }
    }

    if (!theme) {
        theme = Themes.Default;
    }

    return (
        <div className={styles.app}>
            <Header theme={theme} isMenuOpened={menuOpened} onMenuClicked={onMenuClicked}/>
            {/*<div className={styles.container}>*/}
                <main className={`${styles.main}`}>
                    {children}
                </main>
                <BurgerMenu theme={theme} onMenuClicked={onMenuClicked} isOpened={menuOpened}/>
            {/*</div>*/}
            <Footer theme={theme}/>
        </div>
    )
}

export default MainLayout;
