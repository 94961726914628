import styles from './expertise-preview.module.scss';
import PreviewWrapper from '@components/shared/header/nav-previews/preview-wrapper/preview-wrapper';
import Link from 'next/link';
import { ReactSVG } from 'react-svg';
import {
  ExpertisePreviewState
} from '@components/shared/header/nav-previews/expertise-preview/expertise-preview.state';
import { Themes } from '@layouts/main-layout/themes.enum';

type ExpertisePreviewProps = {
  theme: Themes;
}

const ExpertisePreview = ({ theme }: ExpertisePreviewProps) => {
  const { title, subtitle, icon: learnMoreIcon, cards } = ExpertisePreviewState;

  const themedClasses = (classes: string) => `${classes} ${styles[theme]}`;

  return (
    <PreviewWrapper theme={theme}>
      <div className={themedClasses(styles.cards)}>
        <div className={themedClasses(styles.cards__main)}>
          <h3 className={styles.cards__title__lg}>{title}</h3>
          <p className={styles.cards__subtitle}>{subtitle}</p>
          <Link href={'/expertise'}>
            <a className={themedClasses(styles.cards__link)}>
              <span>Learn more</span>
              <ReactSVG className={themedClasses(styles.learn_more)} src={learnMoreIcon}/>
            </a>
          </Link>
        </div>
        {cards.map((card, key) => (
          <div key={key} className={styles.cards__default}>
            <h4 className={`${styles.cards__title__md} ${styles.cards__default_title}`}>{card.title}</h4>
            <div className={styles.cards__body}>
              {card.links.map((link, key) => (
                <Link key={key} href={link.href}>
                  <a className={themedClasses(styles.cards__text)}>
                    <ReactSVG src={link.icon}/>
                    <span>{link.label}</span>
                  </a>
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>
    </PreviewWrapper>
  )
}

export default ExpertisePreview;
