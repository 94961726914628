import Image from 'next/image';
import styles from './footer.module.scss';
import { FooterState, footerStructureData } from './footer.data';
import { Themes } from '@layouts/main-layout/themes.enum';
import Link from 'next/link';
import { Fragment } from 'react';

type FooterProps = {
    theme?: Themes;
}

const Footer = ({ theme }: FooterProps) => {
    const {
        logo,
        rights,
        brand,
        year,
        columns,
        social,
        contacts
    } = FooterState;

    const isLastColumn = (index: number, columns: unknown[]): boolean =>
      columns.length - 1 === index;

    theme ||= Themes.Default;

    return (
        <footer className={`${styles.footer} ${styles[theme]}`}>

            <div className={styles.footer__wrapper}>
                <div className={styles.footer__navigation}>
                  <div className={styles.footer__logo}>
                      <Image
                        src={logo}
                        alt={brand}
                        layout="fixed"
                        objectFit="contain"
                        width={88}
                        height={24}
                        loading="lazy"
                      />
                  </div>
                  {columns.map((column, key, self) => (
                      <div key={key} className={styles.footer__column}>
                        {column.map((item, key) => (
                          <Link key={key} href={item.link}>
                            <a className={`${styles.footer__link} ${item.hTag ? styles.bold : ''} ${item.topGap ? styles.pt : ''}`}>
                              {item.label}
                            </a>
                          </Link>
                        ))}
                       {isLastColumn(key, self) && (
                          <>
                            <span className={`${styles.footer__link} ${styles.bold} ${styles.pt}`}>
                              {contacts.title}
                            </span>
                            <a rel='preload' href={`mailto:${contacts.email}`} className={styles.footer__link}>
                              {contacts.email}
                            </a>
                            <a rel='preload' href={`tel:${contacts.number}`} className={styles.footer__link}>
                              {contacts.number.usa}
                            </a>
                            <a rel='preload' href={`tel:${contacts.number}`} className={styles.footer__link}>
                              {contacts.number.slovakia}
                            </a>
                            <span className={`${styles.footer__link} ${styles.bold} ${styles.pt}`}>
                              {contacts.titleOficce}
                            </span>
                            <span className={`${styles.footer__link}`}>
                              {contacts.address.slovakia}
                            </span>
                            <span className={`${styles.footer__link}`}>
                              {contacts.address.ukraine}
                            </span>
                         
                         
                          </>
                        )}
                      </div>
                  ))}
                </div>
                <div className={styles.footer__social}>
                    <p className={styles.footer__rights}>&#169; {brand} {year}. {rights}</p>
                    <div className={styles.footer__follow}>
                        {social.map((item, key) => (
                          <a href={item.link} key={key} rel="noreferrer" target="_blank">
                              <Image
                                loading='eager'
                                src={item.icon}
                                alt={item.name}
                                layout="fixed"
                                objectFit="contain"
                                width={32}
                                height={32}
                              />
                          </a>
                        ))}
                    </div>
                </div>
            </div>
            <script
                type={'application/ld+json'}
                dangerouslySetInnerHTML={{__html: JSON.stringify(footerStructureData)}}
            />
        </footer>
    )
}

export default Footer;
