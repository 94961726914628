export const ExpertisePreviewState = {
  title: 'Expertise',
  subtitle: 'Get an industry-focused solution for Finance, Media or any other business',
  icon: '/shared/learn-more.svg',
  cards: [
    {
      title: 'Domains',
      links: [
        {
          label: 'Fintech',
          icon: '/layout-images/header/fintech.svg',
          href: '/expertise/fintech',
        },
        {
          label: 'Mobile Banking',
          icon: '/layout-images/header/mobile-banking.svg',
          href: '/expertise/fintech/mobile-banking',
        },
        {
          label: 'Payment solutions',
          icon: '/layout-images/header/payment-solutions.svg',
          href: '/expertise/fintech/payment-solutions',
        },
        {
          label: 'Blockchain software',
          icon: '/layout-images/header/blockchain.svg',
          href: '/expertise/fintech/blockchain-software',
        },
      ]
    },
    {
      title: 'Technologies',
      links: [
        {
          label: 'All technologies',
          icon: '/layout-images/header/technologies.svg',
          href: '/expertise/technology',
        },
        {
          label: 'UX/UI design',
          icon: '/layout-images/header/design.svg',
          href: '/expertise/technology/ui-ux-design',
        },
        {
          label: 'Mobile development',
          icon: '/layout-images/header/mobile.svg',
          href: '/expertise/technology/mobile-development',
        },
        {
          label: 'Web development',
          icon: '/layout-images/header/web.svg',
          href: '/expertise/technology/web-development',
        },
        {
          label: 'QA testing',
          icon: '/layout-images/header/qa.svg',
          href: '/expertise/technology/qa-testing',
        },
        {
          label: 'MVP development',
          icon: '/layout-images/header/mvp.svg',
          href: '/expertise/technology/mvp-development',
        },
      ]
    },
    {
      title: 'Insights',
      links: [
        {
          label: 'Agilie insights',
          icon: '/layout-images/header/insights.svg',
          href: '/expertise/insights'
        },
        {
          label: 'Discovery phase',
          icon: '/layout-images/header/discovery.svg',
          href: '/expertise/discovery'
        },
        {
          label: 'SDLC',
          icon: '/layout-images/header/sdlc.svg',
          href: '/expertise/insights/sdlc'
        },
      ]
    }
  ]
}
