import { isDockerEnv } from '../environment';

export function apiHost(): string {
    if (!isDockerEnv()) {
        return 'http://localhost:3000';
    }
    if (typeof window === 'undefined') {
        /** is server side */
        return 'http://api:3000';
    } else {
        /** is client side */
        return process.env.NEXT_PUBLIC_REACT_APP_API_HOST!;
    }
}
