import { useEffect, useState } from 'react'
import styles from './feedback-form.module.scss'
import { Form, Formik, FormikHelpers } from 'formik';
import { string as yupString, object as yupObject } from 'yup';
import { getCountyCode, sendFeedback } from '@services/feedback.service';
import Grecaptcha from '@libs/integrations/grecaptcha';
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import preloader from '@styles/preloader.module.scss'

interface FeedbackFormProps {
    onSubmit: (success: boolean) => void;
    isFormSubmitted: boolean;
    setFormSubmitted: (status: boolean) => void;
    currentFormStatus: string;
}

interface FormValues {
    name: string;
    email: string;
    message: string;
    phone: string;
}

export default function FeedbackForm({ onSubmit, currentFormStatus, isFormSubmitted, setFormSubmitted }: FeedbackFormProps) {

    const [id, setId] = useState<string | null>(null);
    const [phone, setPhone]: any = useState('+');
    const [isCreatedByClient, setIsCreatedByClient] = useState(true);
    const [defaultPhoneCountry, setDefaultPhoneCountry] = useState('us');
    /** This is done due to this issue:
     * https://stackoverflow.com/questions/59976409/next-js-using-random-properties-without-triggering-did-not-match-on-server-cli
     */
    useEffect(() => {
        setId(Date.now().toString(36));
        getCountyCode().then((country) => {
            if (country.iso_code) {
                setDefaultPhoneCountry(country.iso_code);
            }
        });
    }, []);
    return (
        <>
            <Grecaptcha />
            <Formik
                initialValues={{ name: '', email: '', message: '', phone: '' }}
                validationSchema={yupObject({
                    name: yupString()
                        .required("Please enter your name"),
                    email: yupString()
                        .required("Please enter your email address")
                        .email('Please enter a valid email address'),
                    message: yupString()
                        .required("Please enter your message"),
                    phone: yupString(),
                })}
                onSubmit={async (values: FormValues, actions: FormikHelpers<FormValues>) => {
                    actions.setSubmitting(true);
                    setFormSubmitted(true);
                    Promise.all(
                        [
                            sendFeedback(values.name, values.email, values.message, phone, isCreatedByClient, ``, ``, [],),
                            new Promise((resolve, reject) => {
                                setTimeout(resolve, 500)
                            }),
                        ]
                    ).then(() => {
                        setFormSubmitted(false);
                        onSubmit(true);
                        actions.setSubmitting(false);
                        actions.resetForm();
                        setPhone('');
                    }).catch(err => {
                        setFormSubmitted(false);
                        onSubmit(false);
                        actions.setSubmitting(false);
                        setPhone('');
                    });
                }}
                validateOnBlur={false}
            >
                {formik => (
                    <Form className={`${!isFormSubmitted && currentFormStatus === 'active' ? styles.detail__form__block : styles.detail__form__block_hidden}`} action="" noValidate>

                        <label htmlFor={`name_${id}`} className={styles.detail__form__item}>
                            <div className={styles.input__title}>
                                Full name
                            </div>
                            <div className={styles.detail__form__block__input}>
                                <input {...formik.getFieldProps('name')} placeholder="Your Name"
                                    className={`${styles.detail__form__input} ${formik.errors.name && formik.touched.name && styles.detail__form__input_invalid}`}
                                    type="text" id={`name_${id}`} />
                            </div>
                            {
                                formik.errors.name && formik.touched.name ?
                                    <div
                                        className={styles.subscribe__error_message}>{formik.errors.name}</div> : null
                            }
                        </label>

                        <label htmlFor={`email_${id}`} className={styles.detail__form__item}>
                            <div className={styles.input__title}>
                                Email
                            </div>
                            <div className={styles.detail__form__block__input}>
                                <input {...formik.getFieldProps('email')} placeholder="your@email.com"
                                    className={`${styles.detail__form__input} ${formik.errors.email && formik.touched.email && styles.detail__form__input_invalid}`}
                                    type="email" id={`email_${id}`} />
                            </div>
                            {
                                formik.errors.email && formik.touched.email ?
                                    <div
                                        className={styles.subscribe__error_message}>{formik.errors.email}</div> : null
                            }
                        </label>

                        <label htmlFor={`phone_${id}`} className={styles.detail__form__item}>
                            <div className={styles.input__title}>
                                Phone number (optional)
                            </div>
                            <div className={styles.detail__form__block__input}>
                                <ReactPhoneInput
                                    country={defaultPhoneCountry}
                                    value={phone} onChange={(phone) => setPhone(phone)}
                                    inputClass={'detail__form__input'}
                                    buttonClass={'detail__form__btn'}
                                    dropdownClass={'detail__form__dropdown'}/>
                            </div>
                            {
                                formik.errors.phone && formik.touched.phone ?
                                    <div
                                        className={styles.subscribe__error_message}>{formik.errors.phone}</div> : null
                            }
                        </label>

                        <label htmlFor={`message_${id}`} className={styles.detail__form__item}>
                            <div className={styles.input__title}>
                                Project details
                            </div>
                            <div className={styles.detail__form__block__input}>
                                <textarea {...formik.getFieldProps('message')} placeholder="Your project details"
                                    className={`${styles.detail__form__input} ${styles.detail__form__input_message} ${formik.errors.message && formik.touched.message && styles.detail__form__input_invalid}`}
                                    id={`message_${id}`} />
                            </div>
                            {
                                formik.errors.message && formik.touched.message ?
                                    <div
                                        className={styles.subscribe__error_message}>{formik.errors.message}</div> : null
                            }
                        </label>

                        <button className={styles.detail__form__btn} type={'submit'}>Send</button>
                    </Form>
                )}
            </Formik>
            <div id="preloader" className={`${preloader.form__preloader} ${isFormSubmitted ? preloader.form__preloader_active : preloader.form__preloader_hidden}`}>
                <span className={`${preloader.spinner} ${preloader.spinner_large} ${preloader.spinner_green} ${preloader.spinner_slow}`}></span>
            </div>
        </>
    )
}
