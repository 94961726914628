import styles from './services-mobile-preview.module.scss';
import Link from 'next/link';
import { ServicesPreviewState } from '../services-preview/services-preview.state';
import { Themes } from '@layouts/main-layout/themes.enum';
import { ReactSVG } from 'react-svg';

type ServicesMobilePreviewProps = {
  theme: Themes;
  onMenuClicked: () => void;
}

const ServicesMobilePreview = ({theme, onMenuClicked}: ServicesMobilePreviewProps) => {
  const { cards } = ServicesPreviewState;

  const themedClasses = (classes: string) => `${classes} ${styles[theme]}`;

  return (
    <div className={styles.cards}>
      {cards.map((card, key) => (
          <Link key={key} href={card.link}>
            <a onClick={onMenuClicked} className={styles.cards__default}>
              <ReactSVG src={card.icon} className={themedClasses(styles.cards__default_icon)}/>
              <h4 className={themedClasses(`${styles.cards__title__md} ${styles.cards__default_title}`)}>{card.title}</h4>
            </a>
          </Link>
      ))}
      <Link href="/services">
        <a onClick={onMenuClicked} className={themedClasses(styles.cards__button)}>View all our services</a>
      </Link>
    </div>
  )
}

export default ServicesMobilePreview;
