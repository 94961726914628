import Link from 'next/link'
import style from './header.module.scss'
import Image from 'next/image'
import { useState } from 'react'
import { HeaderNavigation, HeaderState } from './header.state'
import dynamic from 'next/dynamic';
import { NavItem } from './nav-item/nav-item';
import { Themes } from '@layouts/main-layout/themes.enum';

const StartProjectModal = dynamic(() => import('../interactions/start-project/start-project.modal'));

interface HeaderProps {
    isMenuOpened: boolean;
    onMenuClicked: () => void;
    theme?: Themes;
}

const Header = ({ isMenuOpened, onMenuClicked, theme }: HeaderProps) => {
    const [isStartProjectModalOpened, setStartProjectModalOpened] = useState(false);
    const [viewingLink, setViewingLink] = useState<string>('');

    const openStartProjectWindow = function () {
        setStartProjectModalOpened(true);
    }
    const {
        logo,
        logoMobile,
        darkModeLogo,
        darkModeLogoMobile,
        navigation
    } = HeaderState;

    const headerImg = theme === Themes.Dark ?  darkModeLogo : logo;
    const headerMobileImg  = theme === Themes.Dark ? darkModeLogoMobile : logoMobile;

    if (!theme) {
        theme = Themes.Default;
    }

    return (
        <>
            <header className={`${style.header} ${style[theme]}`}>
                <div className={`${style.container}`}>
                    <div className={style.logo}>
                        <Link href="/">
                            <a className={style.header__logo__link}>
                                <Image alt="logo" src={headerImg} width={'88px'} height={'24px'} />
                            </a>
                        </Link>
                    </div>
                    <div className={style.logo_mobile}>
                        <Link href="/">
                            <a className={style.header__logo__link}>
                                <Image alt="logo" src={headerMobileImg} width={'24'} height={'24px'} />
                            </a>
                        </Link>
                    </div>
                    <div className={`${style.flex} ${style.navigation}`}>
                        {navigation.map((item: HeaderNavigation, index: number) => {
                          return (
                            <NavItem setViewingLink={setViewingLink} viewingLink={viewingLink} theme={theme} key={index} item={item}/>
                          )
                        })}
                    </div>
                    <div className={`${style.header__item}`}>
                        <button onClick={openStartProjectWindow} className={`${style.button} ${isMenuOpened ? `${style.button_hidden}` : null}`}>
                            Start a project
                        </button>
                        <div aria-label='burger menu button' className={isMenuOpened ? style.button__burger__block_active : style.button__burger__block}
                            onClick={() => onMenuClicked()}>
                            <div className={style.button__burger}></div>
                        </div>
                    </div>
                </div>
            </header>
            {/* ====== Modals ====== */}
            <StartProjectModal isOpen={isStartProjectModalOpened}
                onClose={() => setStartProjectModalOpened(false)}
            />
        </>
    );
}


export default Header;
