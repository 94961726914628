import styles from "./preview-wrapper.module.scss";
import { FC } from "react";
import { Themes } from '@layouts/main-layout/themes.enum';

type PreviewWrapperProps = {
  theme: Themes;
}

const PreviewWrapper: FC<PreviewWrapperProps> = ({ children, theme }) => {
  return (
    <>
      <div className={`${styles.container} ${styles[theme]}`}>
        <div className={styles.content}>
          {children}
        </div>
      </div>
      <div className={styles.overlay}></div>
    </>
  );
};

export default PreviewWrapper;
