export const ErrorState = {
    image: '/icons/not-found.png',
    sufix: '',
    linkText: ' go to the previous page.',
    text: 'Instead of being upset, you can…',
    items: [
        {
            icon: '/icons/icn_service.png',
            title: 'View our services',
            link: 'https://agilie.com/services',
            width: 32,
            height: 32,
            type: 'link'
        },
        {
            icon: '/icons/icn_projects.png',
            title: 'View projects',
            link: 'https://agilie.com/success-stories',
            width: 32,
            height: 30,
            type: 'link'
        },
        {
            icon: '/icons/icn_technologies.png',
            title: 'View our technologies',
            link: 'https://agilie.com/expertise/technology',
            width: 32,
            height: 32,
            type: 'link'
        },
        {
            icon: '/icons/icn_mail.png',
            title: 'Mail to us',
            link: '',
            width: 32,
            height: 26,
            type: 'form'
        },
    ],
}

export interface IErrorPageButtons {
    icon: string;
    title: string;
    link: string;
    width: number;
    height: number;
    type: string;
}

export interface IErrorState {
    image: string;
    text: string;
    sufix: string;
    linkText: string;
    items: Array<IErrorPageButtons>;
}
