import Image from "next/dist/client/image"
import styles from "./form-submit-status.module.scss"
import successIcon from "/public/assets/icons/icn-success.svg";
import failureIcon from "/public/assets/icons/icn-error.svg"

type FormSubmitStatus = 'success' | 'failure';

interface FormSubmitStatusProps {
    status: FormSubmitStatus;
}

const TITLES = new Map<FormSubmitStatus, string>([
    ['success', 'Application sent'],
    ['failure', 'Application not sent']
]);

const ICONS = new Map<FormSubmitStatus, string>([
    ['success', successIcon],
    ['failure', failureIcon]
]);

const TEXT = new Map<FormSubmitStatus, string>([
    ['success', 'Thank you for leaving a request, we will contact you shortly.'],
    ['failure', 'Unfortunately, something went wrong. Please try again later.']
]);

export default function FormSubmitStatus({ status }: FormSubmitStatusProps) {
    return (
        <div className={styles.container}>
            <div className={`${styles.icon}  ${status === 'success' ? styles.success : styles.error}`}>
                <Image alt="image" width="32px" height="24px" src={ICONS.get(status)!} />
            </div>
            <div className={styles.success__title}>
                {TITLES.get(status)!}
            </div>
            <div className={styles.success__text}>
                {TEXT.get(status)!}
            </div>
        </div>
    )
}
