import { ErrorState, IErrorPageButtons, IErrorState } from "./error.state";
import Image from 'next/image';
import Link from 'next/link';
import style from './error-page.module.scss';
import { useState } from "react";
import StartProjectModal from "../interactions/start-project/start-project.modal";

interface IErrorProps {
    errorStatus: string;
    prefix: string;
}

const ErrorPageComponent = ({ errorStatus, prefix }: IErrorProps) => {
    const { image, text, items, linkText, sufix }: IErrorState = ErrorState;
    const [isStartProjectModalOpened, setStartProjectModalOpened] = useState(false);

    const openStartProjectWindow = function () {
        setStartProjectModalOpened(true);
    }

    const goBack = () => {
        history.back();
    }

    return (
        <>
            <section className={`${style.section}`}>
                <div className={`${style.container}`}>
                    <div className={`${style.container__title}`}>
                        {errorStatus}
                    </div>
                    <div className={`${style.container__text}`}>
                        {prefix}
                        <span onClick={() => goBack()} className={`${style.container__link}`}>
                            {linkText}
                        </span>
                        {sufix}
                    </div>
                    <div className={`${style.container__image}`}>
                        <Image sizes="400px 200px" src={image} alt={errorStatus} layout={'fill'} />
                    </div>
                    <div className={`${style.container__text}`}>
                        {text}
                    </div>
                    <div className={`${style.container__items}`}>
                        {
                            items.map((item: IErrorPageButtons, index: number) => {
                                switch (item.type) {
                                    case 'link': return (
                                        <Link key={index} href={item.link}>
                                            <a className={`${style.item}`}>
                                                <div className={`${style.item__content}`}>
                                                    <Image src={item.icon} alt={item.title} width={item.width} height={item.height} />
                                                    <div className={`${style.item__title}`}>
                                                        {item.title}
                                                    </div>
                                                </div>
                                                <Image src={'/ui-ux-design-images/icons/arrow.png'} alt={'arrow'} width={'24px'} height={'24px'} />
                                            </a>
                                        </Link>
                                    )
                                    case 'form': return (
                                        <div className={`${style.item}`} onClick={openStartProjectWindow} key={index}>
                                            <div className={`${style.item__content}`}>
                                                <Image src={item.icon} alt={item.title} width={item.width} height={item.height} />
                                                <div className={`${style.item__title}`}>
                                                    {item.title}
                                                </div>
                                            </div>
                                            <Image src={'/ui-ux-design-images/icons/arrow.png'} alt={'arrow'} width={'24px'} height={'24px'} />
                                        </div>
                                    )
                                }



                            })
                        }
                    </div>
                </div>
            </section>
            {/* ====== Modals ====== */}
            <StartProjectModal isOpen={isStartProjectModalOpened}
                onClose={() => setStartProjectModalOpened(false)}
            />
        </>
    )
}

export default ErrorPageComponent;
