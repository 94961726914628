export const ServicesPreviewState = {
  title: 'Services',
  subtitle: 'Create complex software',
  icon: '/shared/learn-more.svg',
  cards: [
    {
      title: 'Dedicated teams',
      icon: '/layout-images/header/dedicated-teams.svg',
      text: 'IT experts for all needs',
      link: '/services/dedicated-teams'
    },
    {
      title: 'Staff augmentation',
      icon: '/layout-images/header/staff-augmentation.svg',
      text: 'Add talents to your teams',
      link: '/services/staff-augmentation'
    },
    {
      title: 'Software outsourcing',
      icon: '/layout-images/header/software-outsourcing.svg',
      text: 'Build top-notch software',
      link: '/services/it-outsourcing'
    },
  ]
}
