export const BlogPreviewState = {
  title: 'Blog',
  subtitle: 'Read about technologies',
  icon: '/shared/learn-more.svg',
  cards: [
    {
      title: 'Fintech',
      icon: '/layout-images/header/mobile.svg',
      text: 'Exploring fintech solutions',
      link: '/blog?topic=fintech'
    },
    {
      title: 'Development',
      icon: '/layout-images/header/web.svg',
      text: 'Building tomorrow’s solutions',
      link: '/blog?topic=development'
    },
    {
      title: 'Design',
      icon:'/layout-images/header/design.svg',
      text: 'Latest UX/UI insights',
      link: '/blog?topic=design'
    },
    {
      title: 'Insights',
      icon:'/layout-images/header/insights.svg',
      text: 'IT trends and innovations',
      link: '/blog?topic=insights'
    },
    {
      title: 'Blockchain',
      icon:'/layout-images/header/blockchain.svg',
      text: 'Articles on Blockchain tech',
      link: '/blog?topic=blockchain'
    },
    {
      title: 'Other',
      icon:'/layout-images/header/public.svg',
      text: 'Discovering what’s next',
      link: '/blog?topic=other'
    },
  ]
}
