import '../styles/globals.scss'

import type { AppProps } from 'next/app'
import { isProduction } from '@libs/environment';
import MyAppProduction from './_app.production';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { updateVisitedPage } from '@libs/utils/visitor.utils';
import ErrorPageComponent from '@components/shared/error-page/error-page';
import MainLayout from '@layouts/main-layout/main-layout';

function MyApp({Component, pageProps}: AppProps) {
    // TODO Need to merge MyAppProduction and MyApp components into one
    const router = useRouter();

    useEffect(() => {
        updateVisitedPage(window.location.pathname).catch(err => void 0);
        const handleRouteChange = (url: string) => {
            // Handle app router changes here
            updateVisitedPage(url).catch(err => void 0);
        }

        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        }
    }, [router.events]);

    if (pageProps.error) {
        const {errorStatus, message} = pageProps.error;
        return (
          <MainLayout>
              <ErrorPageComponent
                errorStatus={errorStatus}
                prefix={message}
              />
          </MainLayout>
        )
    }

    return <>
        <Component {...pageProps} />
    </>;
}

export default isProduction() ? MyAppProduction : MyApp;
